<template>
  <v-main class="container-prosker">
    <v-row class="mx-1">
      <v-col cols="12" class="mb-4" v-for="(term, i) in terms" :key="i">
        <h2 class="text-center green-prosk font-weight-bold">{{ $t(term.title) }}</h2>
        <p class="text-justify">{{ $t(term.subtitle) }}</p>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
export default {
  name: 'TermsAndConditions',
  data () {
    return {
      terms: [
        {
          title: 'privacyPolicy',
          subtitle: 'policySubjectToModifications'
        },
        {
          title: 'concepts',
          subtitle: 'conceptsText'
        },
        {
          title: 'the7principles',
          subtitle: 'principlesText'
        },
        {
          title: 'policyObjectives',
          subtitle: 'policyObjectivesText'
        },
        {
          title: 'consentProcessingData',
          subtitle: 'consentProcessingDataText'
        },
        {
          title: 'dataOfMinors',
          subtitle: 'dataOfMinorsText'
        },
        {
          title: 'revocationOfConsent',
          subtitle: 'revocationOfConsentText'
        },
        {
          title: 'consentForProcessingThirdPart',
          subtitle: 'consentForProcessingThirdPartText'
        },
        {
          title: 'whereCollectYourData',
          subtitle: 'whereCollectYourDataText'
        },
        {
          title: 'typesOfTreatment',
          subtitle: 'typesOfTreatmentText'
        },
        {
          title: 'treatYourData',
          subtitle: 'treatYourDataText'
        },
        {
          title: 'whomElseShareYourData',
          subtitle: 'whomElseShareYourDataText'
        },
        {
          title: 'dataBaseHosting',
          subtitle: 'dataBaseHostingText'
        },
        {
          title: 'timeConservationYourData',
          subtitle: 'timeConservationYourDataText'
        },
        {
          title: 'integrationSocialNetwork',
          subtitle: 'integrationSocialNetworkText'
        },
        {
          title: 'security',
          subtitle: 'securityText'
        },
        {
          title: 'databaseLocation',
          subtitle: 'databaseLocationText'
        },
        {
          title: 'placeOfTreatment',
          subtitle: 'placeOfTreatmentText'
        },
        {
          title: 'rightsOfAccess',
          subtitle: 'rightsOfAccessText'
        },
        {
          title: 'modificationOfPolicy',
          subtitle: 'modificationOfPolicyText'
        },
        {
          title: 'cookiesPolicy',
          subtitle: 'cookiesPolicyText'
        },
        {
          title: 'typesOfCookies',
          subtitle: 'typesOfCookiesText'
        },
        {
          title: 'purposeOfCookies',
          subtitle: 'purposeOfCookiesText'
        }
      ]
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }
};
</script>
